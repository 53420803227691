import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-mobile-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BusinessClientHeader = _resolveComponent("BusinessClientHeader")
  const _component_BusinessClientDetails = _resolveComponent("BusinessClientDetails")
  const _component_MobileFooterActions = _resolveComponent("MobileFooterActions")

  return (_openBlock(), _createBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitForm($event)), ["prevent"])),
    id: "new-business-client-form",
    novalidate: ""
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_BusinessClientHeader, {
        name: _ctx.businessClient.name,
        form: 'new-business-client-form',
        editMode: false,
        onBack: _ctx.back,
        isNew: true,
        hideActionsOnMobile: true
      }, null, 8, ["name", "onBack"]),
      _createVNode(_component_BusinessClientDetails, {
        editMode: true,
        businessClientProp: _ctx.businessClient,
        clientContactError: _ctx.clientContactError,
        errorsClientDetails: _ctx.errorsClientDetails,
        isNew: true,
        activeBtn: _ctx.activeBtn,
        onActiveBtn: _ctx.setActiveBtn
      }, null, 8, ["businessClientProp", "clientContactError", "errorsClientDetails", "activeBtn", "onActiveBtn"]),
      _createVNode(_component_MobileFooterActions, {
        editMode: true,
        onBack: _ctx.back,
        form: 'new-business-client-form'
      }, null, 8, ["onBack"])
    ])
  ], 32))
}